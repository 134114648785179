"use client";

import { Center, Image, Stack, Text, useComputedColorScheme } from "@mantine/core";
import { FC } from "react";

interface EmptyStateMessageProps {
  image: string;
  message?: string;
}

export const EmptyStateMessage: FC<EmptyStateMessageProps> = ({ image, message }) => {
  const lightImageSrc = `/empty-state/light/${image}.svg`;
  const darkImageSrc = `/empty-state/dark/${image}.svg`;

  const colorScheme = useComputedColorScheme("light", { getInitialValueInEffect: true });

  return (
    <Stack mt="xl">
      <Center>
        <picture>
          {/* <source srcSet={lightImageSrc} media="(prefers-color-scheme: light)" /> */}
          {/* <source srcSet={darkImageSrc} media="(prefers-color-scheme: dark)" /> */}

          <Image
            src={colorScheme === "light" ? lightImageSrc : darkImageSrc}
            alt={message}
            miw="300"
            w="20%"
            maw="100%"
          />
        </picture>
      </Center>

      {message && (
        <Text c="dimmed" fw={600} ta="center" size="xl">
          {message}
        </Text>
      )}
    </Stack>
  );
};
